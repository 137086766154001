<template>
    <div class="content">
        <!-- <div class="img" v-if="index==0"> 
            <img  src="../../../assets/images/organization/0.png" alt="">
        </div>
        <div class="img" v-else>
            <div class="title">成员</div>
            <img  v-show="index==1" src="../../../assets/images/organization/1.png" alt="">
            <img  v-show="index==2" src="../../../assets/images/organization/2.png" alt="">
            <img  v-show="index==3" src="../../../assets/images/organization/3.png" alt="">
            <img  v-show="index==4" src="../../../assets/images/organization/4.png" alt="">
            <img  v-show="index==5" src="../../../assets/images/organization/5.png" alt="">
            <img  v-show="index==6" src="../../../assets/images/organization/6.png" alt="">
        </div> -->
        <div class="img" v-if="index==0"> 
            <img :src="require('../../../assets/images/organization/'+index+'.png')" alt="">
        </div>
        <div class="img" v-else> 
            <div class="title">成员</div>
            <img :src="require('../../../assets/images/organization/'+index+'.png')" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            index:"",
        }
    },
    created() {
        let index = this.$route.params.index;
        this.init(index);
    },
    methods:{
        init(index){
            this.index = index;
        }
    }
}
</script>

<style scoped lang="less">
    .content{
        width: 100%;
        min-height:14rem ;
        padding: 1rem 5%;
        background: url("../../../assets/images/organization/bg.png");
        .img{
            width: 100%;
            min-height:14rem ;
            .title{
                font-size: 0.36rem;
                font-weight: 700;
                margin: 0 0 10% 3%;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
</style>